<template>
  <div>
    <beautiful-chat
      :participants="participants"
      :titleImageUrl="titleImageUrl"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :title="CalcTitle"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :open="openChat"
      :showEmoji="true"
      :showFile="true"
      :showEdition="false"
      :showDeletion="false"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="false"
      :showCloseButton="false"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :disableUserListToggle="false"
      :messageStyling="messageStyling"
      @onType="handleOnType"
      @edit="editMessage"
    >
      <template v-slot:user-avatar="{message}" @click="openNewChat(message.id, message.author)">
        <div v-if="message.author !== 'me'">
          <img
            :src="message.avatar"
            style="height: 36px; width: 36px; margin-right: 5px; border-radius: 5px"
            @click="openNewChat(message.id, message.author)"
          />
        </div>
        <div v-else>
          <img
            :src="currentUser.imageUrl"
            style="height: 36px; width: 36px; margin-left: 5px; border-radius: 5px"
            @click="openNewChat(message.id, message.author)"
          />
        </div>
      </template>
    </beautiful-chat>
  </div>
</template>

<script>
import * as signalR from '@microsoft/signalr'
let OSS = require('ali-oss')
let _this

export default {
  name: 'App',
  components: {},
  data() {
    _this = this
    return {
      baseUrl: 'https://shapi.dsyun.online', //;  'http://localhost:7710'
      chatRoom: null, //应该从url中获取
      connection: null,
      currentUser: null,
      talkTo: {
        id: null,
        name: null
      },
      pageIndex: 1,
      pageSize: 15,
      maxPageIndex: 9999,
      participants: [],
      title: '···加载中···',
      titleImageUrl: '',
      messageList: [],
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    }
  },
  created() {
    _this.loginToken = _this.getQueryString('token') //this.$route.query.token;
    _this.chatRoom = {
      name: _this.getQueryString('name'), // this.$route.query.name,
      id: _this.getQueryString('id') // this.$route.query.id,
    }
    _this.talkTo = {
      name: decodeURI(_this.getQueryString('tName')), // this.$route.query.name,
      id: _this.getQueryString('tId') // this.$route.query.id,
    }
    if (_this.talkTo == undefined || _this.talkTo.id == null) {
      //如果不是从历史记录那进来
      let mId = _this.getQueryString('marketId')
      if (mId == undefined || mId == null) {
        //获取可用集市（聊天室）
        fetch(_this.baseUrl + '/app/market/MarketOfToday', {
          headers: {
            /*  'Content-Type': 'application/json'   */
            Authorization: 'Bearer ' + _this.loginToken
          }
        })
          .then((response) => response.json())
          .then((respData) => {
            console.log(respData)

            if (respData.status) {
              _this.chatRoom = {
                name: respData.data.title,
                id: respData.data.marketId,
                timestamp: respData.data.timestamp
              }
              // this.titleImageUrl = respData.data.imageUrl
              // if (this.titleImageUrl)
              //   this.imageUrl = 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
              document.title = '牛市'
              _this.title = _this.chatRoom.name
              const con = new signalR.HubConnectionBuilder()
                .withUrl(_this.baseUrl + '/chat', {
                  accessTokenFactory: () => _this.loginToken
                })
                .withAutomaticReconnect()
                .build()
              this.connection = con

              // con.onreconnected((connectionId) => {
              //   console.log('connectionId:' + connectionId)
              // })

              con.onclose(async () => {
                await this.start()
              })
              this.start()

              //(接收)聊天室人员
              con.on('addUser', function (user) {
                // viewModel.userAdded(new ChatUser(user.username, user.fullName, user.avatar, user.currentRoom, user.device));
                if (!_this.participants.some((it) => it.id == user.userId))
                  _this.participants.push({
                    id: user.userId,
                    name: user.userName,
                    imageUrl: user.avatar,
                    currentRoom: user.currentRoom
                  })
                //console.log('addUser:' + user.userId)
              })

              //(接收)删除聊天室人员
              con.on('removeUser', function (user) {
                let index = _this.participants.findIndex((it) => it.id == user.userId)
                _this.participants.splice(index, 1)
              })

              //获取用户头像以及昵称（名字）
              con.on('getProfileInfo', function (userView) {
                // console.log("getProfileInfo:" + userView.userName);
                let it = {
                  id: userView.userId,
                  name: userView.userName,
                  imageUrl: userView.avatar
                }
                _this.currentUser = it
                if (!_this.participants.some((p) => p.id == it.userId)) _this.participants.push(it)
              })

              //(接收)群聊新消息
              con.on('newMessage', function (message) {
                //console.log(message)
                let flag = message.from !== _this.currentUser.id && _this.participants.length > 0
                if (flag) {
                  //防止显示两次
                  var sender = _this.participants.find((it) => it.id == message.from)
                  //console.log(sender)
                  message.content.author = sender.name
                  message.content.avatar = sender.imageUrl
                  _this.messageList.push(message.content)
                }
              })

              con.on('removeChatRoom', function (id) {
                console.log('removeChatRoom:' + id)
              })

              con.on('onError', function (message) {
                console.log(message)
              })
            }
          })
      } else {
        //获取可用集市（聊天室）
        fetch(_this.baseUrl + '/app/market/Detail?id=' + mId, {
          headers: {
            Authorization: 'Bearer ' + _this.loginToken
          }
        })
          .then((response) => response.json())
          .then((respData) => {
            console.log(respData)

            if (respData.status) {
              _this.chatRoom = {
                name: respData.data.title,
                id: respData.data.marketId,
                timestamp: respData.data.timestamp
              }
              this.titleImageUrl = respData.data.imageUrl
              if (this.titleImageUrl)
                this.imageUrl = 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
              document.title = '牛市'
              _this.title = _this.chatRoom.name
              const con = new signalR.HubConnectionBuilder()
                .withUrl(_this.baseUrl + '/chat', {
                  accessTokenFactory: () => _this.loginToken
                })
                //.withAutomaticReconnect()
                .build()
              this.connection = con

              // con.onreconnected((connectionId) => {
              //   console.log('connectionId:' + connectionId)
              // })

              con.onclose(async () => {
                //await this.start();
              })
              this.start()

              //(接收)聊天室人员
              con.on('addUser', function (user) {
                // viewModel.userAdded(new ChatUser(user.username, user.fullName, user.avatar, user.currentRoom, user.device));
                if (!_this.participants.some((it) => it.id == user.userId))
                  _this.participants.push({
                    id: user.userId,
                    name: user.userName,
                    imageUrl: user.avatar,
                    currentRoom: user.currentRoom
                  })
                console.log('addUser:' + user.userId)
              })

              //(接收)删除聊天室人员
              con.on('removeUser', function (user) {
                let index = _this.participants.findIndex((it) => it.id == user.userId)
                _this.participants.splice(index, 1)
              })

              //获取用户头像以及昵称（名字）
              con.on('getProfileInfo', function (userView) {
                // console.log("getProfileInfo:" + userView.userName);
                let it = {
                  id: userView.userId,
                  name: userView.userName,
                  imageUrl: userView.avatar
                }
                _this.currentUser = it
                //_this.participants.push(it);
              })

              //(接收)群聊新消息
              con.on('newMessage', function (message) {
                console.log(message)
                let flag = message.from !== _this.currentUser.id && _this.participants.length > 0
                // console.log(
                //   'message.from:' +
                //     message.from +
                //     ',me:' +
                //     _this.currentUser.id +
                //     ',users:' +
                //     _this.participants.length
                // )
                if (flag) {
                  //防止显示两次
                  var sender = _this.participants.find((it) => it.id == message.from)
                  console.log(sender)
                  message.content.author = sender.name
                  message.content.avatar = sender.imageUrl
                  _this.messageList.push(message.content)
                }
              })

              con.on('removeChatRoom', function (id) {
                console.log('removeChatRoom:' + id)
              })

              con.on('onError', function (message) {
                console.log(message)
              })
            }
          })
      }
    } else {
      var ps = {
        OpenDate: '1900/01/01',
        OpenTime: '1900/01/01 00:00:00',
        CloseTime: '9999/01/01 00:00:00',
        Title: `#$xxx#✤#${_this.talkTo.id}#`,
        LimitedNum: 2,
        MarketId: _this.talkTo.id //用该字段传递用户ID
      }
      //新建聊天室
      fetch(_this.baseUrl + '/app/market/Create', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + _this.loginToken
        },
        method: 'POST',
        body: JSON.stringify(ps)
      })
        .then((response) => response.json())
        .then((respData) => {
          console.log(respData)

          if (respData.status) {
            _this.chatRoom = {
              name: respData.data.title,
              id: respData.data.marketId,
              timestamp: respData.data.timestamp
            }
            _this.title = _this.talkTo.name
            document.title = this.CalcTitle
            this.titleImageUrl = respData.data.imageUrl
            if (this.titleImageUrl)
              this.imageUrl = 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'

            const con = new signalR.HubConnectionBuilder()
              .withUrl(_this.baseUrl + '/chat', {
                accessTokenFactory: () => _this.loginToken
              })
              //.withAutomaticReconnect()
              .build()
            this.connection = con

            // con.onreconnected((connectionId) => {
            //   console.log('connectionId:' + connectionId)
            // })

            con.onclose(async () => {
              //await this.start();
            })
            this.start()

            //(接收)聊天室人员
            con.on('addUser', function (user) {
              // viewModel.userAdded(new ChatUser(user.username, user.fullName, user.avatar, user.currentRoom, user.device));
              if (!_this.participants.some((it) => it.id == user.userId))
                _this.participants.push({
                  id: user.userId,
                  name: user.userName,
                  imageUrl: user.avatar,
                  currentRoom: user.currentRoom
                })
              console.log('addUser:' + user.userId)
            })

            //(接收)删除聊天室人员
            con.on('removeUser', function (user) {
              let index = _this.participants.findIndex((it) => it.id == user.userId)
              _this.participants.splice(index, 1)
            })

            //获取用户头像以及昵称（名字）
            con.on('getProfileInfo', function (userView) {
              // console.log("getProfileInfo:" + userView.userName);
              let it = {
                id: userView.userId,
                name: userView.userName,
                imageUrl: userView.avatar
              }
              _this.currentUser = it
              //_this.participants.push(it);
            })

            //(接收)群聊新消息
            con.on('newMessage', function (message) {
              console.log(message)
              let flag = message.from !== _this.currentUser.id && _this.participants.length > 0
              if (flag) {
                //防止显示两次
                var sender = _this.participants.find((it) => it.id == message.from)
                console.log(sender)
                message.content.author = sender.name
                message.content.avatar = sender.imageUrl
                _this.messageList.push(message.content)
              }
            })

            con.on('removeChatRoom', function (id) {
              console.log('removeChatRoom:' + id)
            })

            con.on('onError', function (message) {
              console.log(message)
            })
          }
        })
    }
  },
  mounted() {
    // document.getElementsByClassName('uni-page-head')[0].style.display = "none";
    // document
    //   .getElementsByClassName("sc-user-input--text")[0]
    //   .setAttribute("placeholder", "请输入...");
    //document.title = _this.chatRoom.name
    document.getElementById('gmh').addEventListener('click', _this.showMoreHistory)
  },
  computed: {
    CalcTitle() {
      if (_this.talkTo.id !== undefined && _this.talkTo.id !== null) return `${this.title}`
      if (_this.getQueryString('marketId') !== undefined) return `${this.title}`
      else return `${this.title}${this.participants.length}人`
    }
  },
  methods: {
    showMoreHistory() {
      _this.alwaysScrollToBottom = false
      let source = document.getElementById('gmh')
      if (_this.pageIndex > _this.maxPageIndex) {
        //没有更多数据就隐藏
        source.removeEventListener('click', _this.showMoreHistory)
        //source.style.display = "none";
        source.innerHTML = '已全部加载'
      } else {
        _this.getHistoryMessages()
        _this.pageIndex++
      }
    },
    openNewChat(tId, tName) {
      if (tName != 'me') {
        let url = `${window.location.href.replace(
          window.location.search,
          ''
        )}?token=${this.getQueryString('token')}&tId=${tId}&tName=${tName}&rn=${Math.random()}`
        window.location.href = url
      }
    },
    getQueryString(variable) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == variable) {
          return pair[1]
        }
      }
      return undefined
    },
    async start() {
      try {
        await _this.connection.start()
        // console.log('roomName:' + _this.chatRoom.name)
        //获取当前聊天室用户
        _this.connection.invoke('GetUsers', _this.chatRoom.name).then(function (result) {
          for (var i = 0; i < result.length; i++) {
            if (!_this.participants.some((item) => item.id == result[i].userId))
              _this.participants.push({
                id: result[i].userId,
                name: result[i].userName,
                imageUrl: result[i].avatar,
                currentRoom: result[i].currentRoom
              })
          }
          //console.log('GetUsers:' + _this.participants.length)
        })
        //加入聊天室
        _this.connection
          .send('Join', _this.chatRoom)
          // .then(resp => resp.json())
          .then((respData) => {
            //console.log('Join:' + respData)
          })
        this.showMoreHistory()
      } catch (err) {
        console.log(err)
        await setTimeout( _this.start, 5000)
      }
    },
    getHistoryMessages() {

      let ps = {
        pageIndex: _this.pageIndex,
        pageSize: _this.pageSize,
        id: _this.chatRoom.id,
        timestamp: Math.round(new Date().getTime()/1000) //_this.chatRoom.timestamp
      }
      //获取聊天记录、聊天记录人数
      fetch(_this.baseUrl + '/app/market/OriginalMsg?rn=' + Math.random(), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + _this.loginToken
        },
        method: 'POST',
        body: JSON.stringify(ps)
      })
        .then((response) => response.json())
        .then((respData) => {
          if (respData.status) {
            _this.maxPageIndex = Math.ceil(
              (respData.data.messages.totalRows * 1.0) / _this.pageSize
            )
            respData.data.users.forEach((it) => {
              if (!_this.participants.some((item) => item.id == it.id))
                _this.participants.push(it)
            })
            // console.log(respData.data.messages)
            respData.data.messages.data.forEach((msg) => {
              var sender = _this.participants.find((it) => it.id == msg.from)
              if (sender.id != _this.currentUser.id) {
                msg.content.author = sender.name
                msg.content.avatar = sender.imageUrl
              } else {
                msg.content.avatar = _this.currentUser.imageUrl
              }
              //_this.messageList.push(msg.content)
              _this.messageList.unshift(msg.content)
            })
          }
        })
    },
    isAssetTypeAnImage(ext) {
      return (
        ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(
          ext.toLowerCase()
        ) !== -1
      )
    },
    getUserByName(userName) {
      let obj = this.participants.find((it) => it.name == userName)
      return obj
    },
    async onMessageWasSent(message) {
      // called when the user sends a message
      this.messageList = [...this.messageList, message]
      this.alwaysScrollToBottom = true
      if (message.type == 'file') {
        await fetch(_this.baseUrl + '/api/oss/getSts', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + _this.loginToken
          },
          method: 'GET'
        }).then(async (x) => {
          const json = await x.json()
          if (!x.status) return console.log(x.message)
          else {
            //console.log(json)
            let client = new OSS({
              // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
              region: json.data.region,
              // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
              accessKeyId: json.data.accessKeyId,
              accessKeySecret: json.data.accessKeySecret,
              // 从STS服务获取的安全令牌（SecurityToken）。
              stsToken: json.data.securityToken,
              // 填写Bucket名称。
              bucket: json.data.bucket
            })

            let result = await client.put(
              json.data.bucketFolder + '/' + json.data.unique + message.data.file.name,
              message.data.file
            )
            let index = message.data.file.name.lastIndexOf('.')
            //获取后缀
            let ext = message.data.file.name.substr(index + 1).toLowerCase()
            message.data = {
              file: {
                name: message.data.file.name,
                url: this.isAssetTypeAnImage(ext)
                  ? result.url + '?x-oss-process=image/resize,w_200/format,webp/quality,lossless'
                  : result.url
              },
              text: message.data.text
            }
          }
        })
      }

      //(发送)群聊消息
      let msg = {
        From: _this.currentUser.id,
        Room: _this.chatRoom.marketId,
        Content: message
      }
      await _this.connection.send('SendMessage', _this.chatRoom, msg)
    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
    },
    handleScrollToTop() {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType() {
      console.log('Emit typing event')
    },
    editMessage(message) {
      const m = this.messageList.find((m) => m.id === message.id)
      m.isEdited = true
      m.data.text = message.data.text
    }
  }
}

window.onbeforeunload = function (e) {
  _this.connection.stop()
}
</script>


<style>
body {
  padding: 0px;
  margin: 0px;
}

* {
  font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
}

.demo-description {
  max-width: 500px;
}

.demo-description img {
  max-width: 500px;
}

/* .demo-test-area {
  width: 300px;
  box-sizing: border-box;
}

.demo-test-area--text {
  box-sizing: border-box;
  width: 100%;
  margin: 0px;
  padding: 0px;
  resize: none;
  font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
  background: #fafbfc;
  color: #8da2b5;
  border: 1px solid #dde5ed;
  font-size: 16px;
  padding: 16px 15px 14px;
  margin: 0;
  border-radius: 6px;
  outline: none;
  height: 150px;
  margin-bottom: 10px;
} */

.demo-monster-img {
  width: 400px;
  display: block;
  margin: 60px auto;
}

.text-center {
  text-align: center;
}

.colors a {
  color: #fff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 10px;
}

.toggle a {
  text-decoration: none;
}

.messageStyling {
  font-size: small;
}
</style>
